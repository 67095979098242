<template>
    <div class="show-in-turn" :class="inTurn.account.commercial_in_turn ? 'col-md-4' : 'col-md-6'">
        <div class="box | mb-6">
            <div class="box-header | border-b">
                <h4 class="box-title | text-center w-full">
                    {{ boxTitle }}
                </h4>
            </div>
            <div class="box-body" v-if="inTurn.users.length">
                <div class="products-list product-list-in-box">
                    <div class="info-box | bg-green-500 text-white flex items-center m-0" v-if="nextUser">
                        <div class="info-box-img">
                            <img :src="nextUser.profile_picture" alt="User Image" />
                        </div>

                        <div class="info-box-content | m-0 px-4 flex flex-1 items-center">
                            <div class="flex-1">
                                <div class="info-box-number">
                                    {{ nextUser.first_name }} {{ nextUser.last_name }}
                                </div>
                                <div class="info-box-text">
                                    {{ $t('database.post_' + nextUser.post.name) }}
                                </div>
                            </div>
                            <div class="flex items-center text-white">
                                <span class="mr-2" v-if="getStatus(nextUser) == 'appointment'">
                                    {{ formatAppt(nextUser) }}
                                </span>
                                <icon class="text-2xl" :name="getIcon(nextUser)" />
                            </div>
                        </div>
                    </div>
                    <div class="info-box | bg-orange-500 text-white flex items-center m-0" v-else>
                        <div class="info-box-icon">
                            <icon name="bold/close" />
                        </div>

                        <div class="info-box-content | m-0 px-4 flex flex-1 items-center">
                            <div class="info-box-number">
                                {{ noUserText }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="item"
                        :class="{
                            'opacity-50':
                                user.pivot.break_date ||
                                user.pivot.delivery_date ||
                                user.pivot.meeting_date ||
                                (user.pivot.planned_meeting_date && inTurn.account.in_turn_planned_meeting),
                        }"
                        :key="index"
                        v-for="(user, index) in usersToShow"
                    >
                        <activix-avatar
                            class="rounded-full shadow-inner text-2xl"
                            :size="50"
                            :src="user.profile_picture"
                        />
                        <div class="product-info">
                            <div class="product-title">
                                {{ user.first_name }} {{ user.last_name }}
                            </div>
                            <div class="product-description" v-if="!empty(user.post)">
                                {{ $t('database.post_' + user.post.name) }}
                            </div>
                        </div>
                        <div class="flex items-center text-grey-500">
                            <span class="mr-2" v-if="getStatus(user) == 'appointment'">
                                {{ formatAppt(user) }}
                            </span>
                            <icon class="text-2xl" :name="getIcon(user)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-body" v-else>
                <div class="products-list product-list-in-box">
                    <div class="info-box | bg-orange-500 text-white flex items-center m-0">
                        <div class="info-box-icon">
                            <icon name="bold/close" />
                        </div>

                        <div class="info-box-content | m-0 py-6 px-4 flex flex-1 items-center">
                            <div class="info-box-number">
                                {{ noUserText }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: '',
            },
            inTurn: {
                type: Object,
                default: () => {},
            },
        },

        computed: {
            nextUser() {
                return this.inTurn.users.find(user => {
                    return (
                        (!user.pivot.planned_meeting_date || !this.inTurn.account.in_turn_planned_meeting) &&
                        !user.pivot.break_date &&
                        !user.pivot.delivery_date &&
                        !user.pivot.meeting_date
                    );
                });
            },

            usersToShow() {
                return this.inTurn.users
                    .filter(user => {
                        return (
                            (!user.pivot.planned_meeting_date || !this.inTurn.account.in_turn_planned_meeting) &&
                            (!this.nextUser || this.nextUser.id != user.id) &&
                            !user.pivot.meeting_date
                        );
                    })
                    .slice(0, 10);
            },

            noUserText() {
                if (this.type == 'financial') {
                    return this.$t('inturns.noDirector');
                }

                if (this.type == 'deskManager') {
                    return this.$t('inturns.noDeskManager');
                }

                return this.$t('inturns.noUser');
            },

            boxTitle() {
                return this.$t(`inturns.${this.type}Vehicle`);
            },
        },

        methods: {
            getStatus(user) {
                if (user.pivot.break_date) {
                    return 'break';
                }
                if (user.pivot.delivery_date) {
                    return 'delivery';
                }
                if (user.leads.length > 0) {
                    const date = now();
                    const appt = user.leads.some(lead => {
                        const appointmentDate = as_locale(lead.appointment_date, 'appointment_date');

                        return (
                            date.diff(appointmentDate, 'minutes') < 0 && date.diff(appointmentDate, 'minutes') > -121
                        );
                    });

                    if (appt) {
                        return 'appointment';
                    }
                }

                return 'available';
            },

            getIcon(user) {
                switch (this.getStatus(user)) {
                    case 'break':
                        return 'regular/coffee-cup';
                    case 'delivery':
                        return 'regular/car-1';
                    case 'appointment':
                        return 'regular/calendar-3';
                }

                return 'regular/check-2';
            },

            formatAppt(user) {
                if (!user.leads.length) {
                    return '';
                }

                const date = now();
                const lead = user.leads.find(lead => {
                    const appointmentDate = as_locale(lead.appointment_date, 'appointment_date');

                    return appointmentDate.isValid() && date.diff(appointmentDate, 'minutes') < 121;
                });

                if (lead) {
                    return as_locale(lead.appointment_date, 'appointment_date').toTimeShortString();
                }

                return '';
            },
        },
    };
</script>
