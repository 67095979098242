<template>
    <div class="row" v-if="ready">
        <div class="col-md-9 | -mb-6">
            <div class="row">
                <show-in-turn-column type="new" :in-turn="inTurnNew" />
                <show-in-turn-column type="used" :in-turn="inTurnUsed" />
                <show-in-turn-column
                    type="financial"
                    :in-turn="inTurnFinancial"
                    v-if="currentInTurns[0].account.commercial_in_turn"
                />
            </div>

            <div class="row">
                <show-in-turn-column
                    type="deskManager"
                    :in-turn="inTurnDeskManager"
                    v-if="currentInTurns[0].account.desk_manager_in_turn"
                />
            </div>
        </div>

        <status-in-turn-column :in-turns="currentInTurns" />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import ShowInTurnColumn from './ShowInTurnColumn.vue';
    import StatusInTurnColumn from './StatusInTurnColumn.vue';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ShowInTurnColumn,
            StatusInTurnColumn,
        },

        data() {
            return {
                currentInTurns: '',
                ready: false,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            inTurnNew() {
                for (let i = 0; i < this.currentInTurns.length; i++) {
                    if (this.currentInTurns[i].in_turn_type.name == 'new') {
                        return this.currentInTurns[i];
                    }
                }

                return null;
            },

            inTurnUsed() {
                for (let i = 0; i < this.currentInTurns.length; i++) {
                    if (this.currentInTurns[i].in_turn_type.name == 'used') {
                        return this.currentInTurns[i];
                    }
                }

                return null;
            },

            inTurnFinancial() {
                for (let i = 0; i < this.currentInTurns.length; i++) {
                    if (this.currentInTurns[i].in_turn_type.name == 'financial') {
                        return this.currentInTurns[i];
                    }
                }

                return null;
            },

            inTurnDeskManager() {
                for (let i = 0; i < this.currentInTurns.length; i++) {
                    if (this.currentInTurns[i].in_turn_type.name == 'deskManager') {
                        return this.currentInTurns[i];
                    }
                }

                return nul;
            },
        },

        watch: {
            'contextAccount.id'() {
                this.fetchInTurns();
            },
        },

        methods: {
            async fetchInTurns() {
                try {
                    const response = await this.$axios.get('v1/in-turn', {
                        params: {
                            accountId: this.contextAccount.id,
                        },
                    });

                    if (response.data.success) {
                        this.currentInTurns = response.data.inTurns;

                        this.$nextTick(() => {
                            this.$emit('in-time');
                        });

                        this.ready = true;
                    }
                } catch (error) {
                    this.$notify.error(this.$t('inturns.alerts.update.error'));
                }
            },
        },

        created() {
            this.fetchInTurns();

            this.$eventBus.$on('update-in-turn', this.fetchInTurns);
        },

        beforeDestroy() {
            this.$eventBus.$off('update-in-turn', this.fetchInTurns);
        },
    };
</script>
