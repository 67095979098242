<template>
    <div class="show-in-turn col-md-3">
        <!-- Meeting -->
        <div class="box | mb-6">
            <div class="box-header | flex justify-between items-center border-b">
                <icon name="regular/single-man-actions" class="text-xl text-grey-500" />
                <h4 class="box-title">
                    {{ $t('inturns.meeting_date') }}
                </h4>
                <span class="label label-primary">{{ meetingUsers.length }}</span>
            </div>

            <div class="box-body p-0">
                <div class="users-list">
                    <div :key="index" v-for="(user, index) in meetingUsers">
                        <activix-avatar
                            class="rounded-full shadow-inner text-2xl"
                            :size="50"
                            :src="user.profile_picture"
                            v-if="meetingUsers.length <= 3 && breakUsers.length <= 3 && deliveryUsers.length <= 3"
                        />
                        <div class="font-semibold">
                            {{ user.first_name }}<br />{{ user.last_name }}
                        </div>
                        <div v-if="user.inTime">
                            <span class="label label-primary">{{ user.inTime }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Break -->
        <div class="box | mb-6">
            <div class="box-header | flex justify-between items-center border-b">
                <icon name="regular/coffee-cup" class="text-xl text-grey-500" />
                <h4 class="box-title">
                    {{ $t('inturns.break_date') }}
                </h4>
                <span class="label label-primary">{{ breakUsers.length }}</span>
            </div>

            <div class="box-body p-0">
                <div class="users-list">
                    <div :key="index" v-for="(user, index) in breakUsers">
                        <activix-avatar
                            class="rounded-full shadow-inner text-2xl"
                            :size="50"
                            :src="user.profile_picture"
                            v-if="meetingUsers.length <= 3 && breakUsers.length <= 3 && deliveryUsers.length <= 3"
                        />
                        <div class="font-semibold">
                            {{ user.first_name }}<br />{{ user.last_name }}
                        </div>
                        <div v-if="user.inTime">
                            <span
                                :class="[
                                    'label',
                                    {
                                        'label-success': user.diffTime == 'onTime',
                                        'label-warning': user.diffTime == 'late',
                                        'label-danger': user.diffTime == 'veryLate',
                                    },
                                ]"
                            >{{ user.inTime }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delivery -->
        <div class="box | mb-6">
            <div class="box-header | flex justify-between items-center border-b">
                <icon name="regular/car-1" class="text-xl text-grey-500" />
                <h4 class="box-title">
                    {{ $t('inturns.delivery_date') }}
                </h4>
                <span class="label label-primary">{{ deliveryUsers.length }}</span>
            </div>

            <div class="box-body p-0">
                <div class="users-list">
                    <div :key="index" v-for="(user, index) in deliveryUsers">
                        <activix-avatar
                            class="rounded-full shadow-inner text-2xl"
                            :size="50"
                            :src="user.profile_picture"
                            v-if="meetingUsers.length <= 3 && breakUsers.length <= 3 && deliveryUsers.length <= 3"
                        />
                        <div class="font-semibold">
                            {{ user.first_name }}<br />{{ user.last_name }}
                        </div>
                        <div v-if="user.inTime">
                            <span class="label label-primary">{{ user.inTime }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    export default {
        props: {
            inTurns: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                timeOut: null,
            };
        },

        computed: {
            meetingUsers() {
                const vm = this;
                const tempUsers = [];

                for (let i = 0; i < vm.inTurns.length; i++) {
                    for (let j = 0; j < vm.inTurns[i].users.length; j++) {
                        if (vm.inTurns[i].users[j].pivot.meeting_date) {
                            tempUsers.push(vm.inTurns[i].users[j]);
                        } else if (
                            vm.inTurns[i].users[j].pivot.planned_meeting_date &&
                            vm.inTurns[i].account.in_turn_planned_meeting
                        ) {
                            tempUsers.push(vm.inTurns[i].users[j]);
                        }
                    }
                }
                return tempUsers;
            },

            deliveryUsers() {
                const vm = this;
                const tempUsers = [];

                for (let i = 0; i < vm.inTurns.length; i++) {
                    for (let j = 0; j < vm.inTurns[i].users.length; j++) {
                        if (vm.inTurns[i].users[j].pivot.delivery_date) {
                            tempUsers.push(vm.inTurns[i].users[j]);
                        }
                    }
                }
                return tempUsers;
            },

            breakUsers() {
                const vm = this;
                const tempUsers = [];

                for (let i = 0; i < vm.inTurns.length; i++) {
                    for (let j = 0; j < vm.inTurns[i].users.length; j++) {
                        if (vm.inTurns[i].users[j].pivot.break_date) {
                            tempUsers.push(vm.inTurns[i].users[j]);
                        }
                    }
                }
                return tempUsers;
            },
        },

        methods: {
            inTime() {
                const vm = this;
                const currentTime = now();

                for (let i = 0; i < vm.inTurns.length; i++) {
                    for (let j = 0; j < vm.inTurns[i].users.length; j++) {
                        let userTime = null;

                        if (vm.inTurns[i].users[j].pivot.break_date) {
                            userTime = as_locale(vm.inTurns[i].users[j].pivot.break_date, 'break_date');

                            if (currentTime.diff(userTime, 'hours') >= 2) {
                                vm.inTurns[i].users[j].diffTime = 'veryLate';
                            } else if (currentTime.diff(userTime, 'hours') >= 1) {
                                vm.inTurns[i].users[j].diffTime = 'late';
                            } else {
                                vm.inTurns[i].users[j].diffTime = 'late';
                            }
                        } else if (vm.inTurns[i].users[j].pivot.delivery_date) {
                            userTime = as_locale(vm.inTurns[i].users[j].pivot.delivery_date, 'delivery_date');
                        } else if (vm.inTurns[i].users[j].pivot.meeting_date) {
                            userTime = as_locale(vm.inTurns[i].users[j].pivot.meeting_date, 'meeting_date');
                        } else if (vm.inTurns[i].users[j].pivot.planned_meeting_date) {
                            userTime = as_locale(
                                vm.inTurns[i].users[j].pivot.planned_meeting_date,
                                'planned_meeting_date',
                            );
                        }

                        if (userTime) {
                            const stringTime = vm.timeHms(currentTime.diff(userTime, 'seconds'), true);
                            vm.$set(this.inTurns, `${i}.users.${j}.inTime`, stringTime);
                            vm.inTurns[i].users[j].inTime = stringTime;
                        }
                    }
                }

                if (typeof this.timeOut !== 'undefined' && this.timeOut !== null) {
                    clearTimeout(this.timeOut);
                }

                this.timeOut = setTimeout(() => {
                    vm.inTime();
                }, 10000);
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.inTime();
            });
        },
    };
</script>
