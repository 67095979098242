<template>
    <div class="flex flex-col">
        <warning
            icon="regular/monitor-play"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount"
        />
        <warning
            icon="regular/monitor-play"
            :title="$t('error.warning')"
            :content="$t('error.noInTurn')"
            :show-back="true"
            :show-home="true"
            v-else-if="!contextAccount.in_turn"
        />
        <show-in-turn :class="{ loading: isLoading }" v-else />
    </div>
</template>

<script>
    import { get } from 'lodash-es';

    import { mapState } from 'pinia';
    import ShowInTurn from '../../components/in_turn/ShowInTurn.vue';
    import Warning from '../../components/Warning.vue';
    import TrackView from '../../mixins/TrackView.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ShowInTurn,
            Warning,
        },

        mixins: [TrackView],

        computed: {
            ...mapState(useContextStore, {
                contextChildAccount: 'contextChildAccount',
                contextAccount: 'account',
            }),

            isLoading() {
                return this.$wait.is('fetching.inturns');
            },

            validAccount() {
                return get(this.contextChildAccount, 'id');
            },
        },
    };
</script>
